@import "../../styles/base.scss";

.prediction_header {
  &.with_background {
    padding: px-to-vw(14, 390);
    background-color: $background-secondary;
    border-radius: px-to-vw(5, 390);
    margin-bottom: px-to-vw(24, 390);
    border: px-to-vw(1, 390) solid $gray;
  }
  .prediction_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: px-to-vw(8, 390);

    .first_group {
      display: flex;
      gap: px-to-vw(4, 390);
    }

    .group {
      display: flex;
      align-items: center;
      gap: px-to-vw(8, 390);
      padding: px-to-vw(8, 390) 0;
      min-width: 54px;
      .competition_name {
        text-align: right;
      }
      .icon {
        width: px-to-vw(16, 390);
        height: px-to-vw(16, 390);
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      p {
        font-family: $rubik;
        font-size: px-to-vw(12, 430);
        font-style: normal;
        font-weight: 300;
        line-height: px-to-vw(18, 430);
        color: $white;
        margin: 0;
      }
    }
  }

  .divider {
    hr {
      height: 0;
      border: 0;
      border-bottom: px-to-vw(2, 390) solid $primary;
      margin: 0;
    }
  }
  .teams {
    display: flex;
    justify-content: space-between;
    .live {
      padding: px-to-vw(4, 390) px-to-vw(6, 430);
      background-color: $primary;
      height: max-content;
      position: relative;
      border-radius: px-to-vw(5, 390);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: px-to-vw(4, 390);
      .bullet{
        display: block;
        width: px-to-vw(8, 390);
        height: px-to-vw(8, 390);
        background: $red;
        border-radius: 100%;
      }
      p {
        color: $black;
        font-size: px-to-vw(12, 390);
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
      }
    }
    .inner_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: px-to-vw(12, 390);
      margin-bottom: px-to-vw(12, 390);

      .team_one,
      .team_two {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: px-to-vw(10, 390);
        flex: 1;
        width: 100%;
        .name {
          font-size: px-to-vw(16, 390);
          font-weight: 700;
          line-height: px-to-vw(24, 390);
          text-transform: uppercase;
          max-width: max-content;
          color: $white;
        }
        .flag {
          display: block;
          width: px-to-vw(24, 430);
          height: px-to-vw(24, 430);

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
