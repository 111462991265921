@import "../../../styles/base.scss";

.correct_score {
  h2 {
    font-size: px-to-vw(16, 390);
    line-height: px-to-vw(18, 390);
    font-weight: 700;
    color: $white;
    margin:  0 0 px-to-vw(16, 390) 0;
      text-transform: capitalize;
    }  

  .teams_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: px-to-vw(10, 390);

    .team_container {
      display: flex;
      flex-direction: column;
      gap: px-to-vw(12, 390);
      flex: 1;

      .score_container {
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
        align-items: center;
        justify-content: space-between;
        gap: px-to-vw(9, 390);
        border-radius: px-to-vw(5, 390);
        overflow: hidden;
        background: $primary;
        width: 100%;
        height: px-to-vw(44, 390);
        border: px-to-vw(1, 390) solid $gray;

        button {
          background-color: $background-secondary;
          border: none;
          font-size: px-to-vw(18, 390);
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          color: $white;
          padding: px-to-vw(12, 390) px-to-vw(16, 390);
        }

        .score {
          font-size: px-to-vw(18, 390);
          font-weight: 500;
          color: $black;
          margin: 0;
          text-align: center;
        }
      }
    }
  }

  .odd {
    text-align: center;
    width: max-content;
    margin: 0 auto;
    margin-top: px-to-vw(8, 390);
    font-family: $rubik;
    font-size: px-to-vw(12, 390);
    line-height: px-to-vw(18, 390);
    color: $white;
  }
}
