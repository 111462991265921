@import "../../styles/base.scss";

.switcher {
  display: flex;
  border-radius: px-to-vw(5, 390);
  border: px-to-vw(1, 390) solid $primary;
  overflow: hidden;
  margin: px-to-vw(15, 390) 0;


  .button {
    flex: 1;
    padding: px-to-vw(13, 390) px-to-vw(2, 390);
    border: none;
    background: $background;
    font-family: $rubik;
    font-size: px-to-vw(12, 390);
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    color: $white;

    &.active {
      background-color: $primary;
      color: $black;
    }
  }
}
