@import "../../styles/base.scss";

.button {
    font-size: px-to-vw(14, 390);
    font-weight: 500;
    line-height: px-to-vw(24, 390);
    padding: px-to-vw(2, 390);
    border: px-to-vw(1, 390) solid $primary;
    border-radius: px-to-vw(5, 390);
    text-transform: uppercase;
    text-align: center;
    width: 100%;

  &.small {
    height: px-to-vw(30, 390);
  }

  &.large {
    height: px-to-vw(40, 390);
  }

  &.primary {
    background-color: $primary;
    color: $black;
  }

  &.secondary {
    background-color: $background-secondary;
    color: $white;
  }
}
