@import "../../styles/base.scss";

.prizes_container {
  margin-top: px-to-vw(24, 430);
  padding-bottom: 80px;
  .filters {
    display: flex;
    gap: px-to-vw(13, 430);
    .button {
      flex: 1;
      padding: px-to-vw(13, 430) px-to-vw(2, 430);
      border: 1.5px solid $white;
      border-radius: px-to-vw(4, 430);
      background-color: rgba($white, 0.2);
      font-family: $rubik;
      font-size: px-to-vw(12, 430);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      color: $white;

      &.active {
        background-color: $primary;
        color: $black;
        border-color: $primary;
      }
    }
  }
  .prizes {
    .prize_card {
      margin: px-to-vw(15, 390) 0;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .prize_info{
      h2{
        font-size: px-to-vw(16, 390);
        font-weight: 800;
        color: $white;
        margin: 0 0 px-to-vw(10, 390) 0;
        text-transform: uppercase;
      }
      p{
        font-size: px-to-vw(16, 390);
        font-weight: 400;
        line-height: px-to-vw(18, 390);
        color: $white;
      }
    }
  }
  .rules {
    padding: 0 px-to-vw(14, 390);
    margin: px-to-vw(15, 390) 0;

    border-radius: px-to-vw(5, 390);
    background-color: $background-secondary;
    border: px-to-vw(1, 390) solid $gray;

    .single {
      display: block;
      margin: px-to-vw(24, 390) 0;
      h3 {
        color: $white;
        font-size: px-to-vw(12, 390);
        font-weight: 700;
        line-height: px-to-vw(18, 390);
        margin-bottom: px-to-vw(8, 390);
      }
      p {
        color: $white;
        font-size: px-to-vw(12, 390);
        font-weight: 400;
        line-height: px-to-vw(18, 390);
      }
    }
    }
}
