@import "../../../styles/base.scss";

.no_odds {
  font-family: $rubik;
  font-size: px-to-vw(14, 430);
  line-height: px-to-vw(18, 430);

  h2 {
    font-size: px-to-vw(16, 390);
    line-height: px-to-vw(18, 390);
    font-weight: 700;
    color: $white;
    margin:  0 0 px-to-vw(16, 430) 0;
  }
  .container {
    padding: px-to-vw(14, 430);
    color: $white;
    border: none;
    border-radius: px-to-vw(4, 430);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
