@import "../../styles/base.scss";

.banner{
    text-align: center;
    background-color: $primary;
    padding: px-to-vw(12, 390);

    .appTitle{
        font-size: px-to-vw(20, 390);
        letter-spacing: 0px;
        font-weight: 600;
        margin: 0;
        color: $black;
    }
}

nav{
    margin: px-to-vw(15, 390) 0;
    padding: 0 px-to-vw(12, 390);
}

.container{
    padding: 0 px-to-vw(12, 390);
}