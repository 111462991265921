// Typography
$rubik: "Rubik", sans-serif;
$work-sans: "Work Sans", sans-serif;
$roboto: "Roboto", sans-serif;


$black: #000;
$white: #fff;

$background: #081117;
$background-secondary: #121B21;
$primary: #00ADEE;

$gray: #353A3E;




$light-gray: #e5e5e5;
$medium-gray: #5e5e5e;
$green: #3fe193;
$red: #f50000;