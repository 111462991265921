@import "../../styles/base.scss";

.pop_up_prediction_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-y: scroll;
  background: $background;

  .pop_up_container__inner {
    display: block;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    height: auto;
    overflow: auto;

    padding: px-to-vw(46, 390) px-to-vw(28, 390) px-to-vw(46, 390);
    display: flex;
    gap: px-to-vw(15, 390);

    .btn_close__container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: px-to-vw(20, 390);

      .btn_close {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: 0;

        img {
          width: px-to-vw(14, 390);
          height: auto;
        }
      }
    }
    h3{
      margin: 0;
    }
    .playerList {
      display: flex;
      flex-direction: column;
      gap: px-to-vw(10, 390);

      .player {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $background-secondary;
        cursor: pointer;
        border-radius: px-to-vw(5, 390);
        border: px-to-vw(1, 390) solid $gray;
        padding: px-to-vw(8, 390);

        &.selected {
          background-color: $primary;
          color: $black;
          font-weight: 600;
        }
      }
    }
  }
  .button_container{
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 px-to-vw(28, 390) px-to-vw(46, 390);
    background: $background;
    button{
      width: 100%;
    }
  }
}
