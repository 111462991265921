@import "../../styles/base.scss";

.leaderboards {

  .clasament_container {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: px-to-vw(8, 390);

    .clasament_item {
      display: block;
      height: px-to-vw(65, 390);
      background: $background-secondary;
      padding: px-to-vw(12,390) px-to-vw(16,390);
      border-radius: px-to-vw(5, 430);
      display: flex;
      align-items: center;
      gap: px-to-vw(16, 390);
      color: $white;

      .icon_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: px-to-vw(30,390);
      }
      
      .icon {
        width: auto;
        height: 5.581vw;
      
        &.first_place {
          width: 5.581vw;
          height: auto;
        }
      }

      &.first_places {
        .position {
          width: auto;
        }
      }

      .position {
        font-size: px-to-vw(16, 430);
        line-height: px-to-vw(22, 430);
        font-weight: 700;
        width: px-to-vw(25, 430);
        text-align: center;
      }

      .name {
        font-size: px-to-vw(16, 430);
        font-weight: 400;
        line-height: px-to-vw(18, 430);
      }

      .points {
        flex-grow: 1;
        text-align: right;
        font-size: px-to-vw(16, 430);
        font-weight: 700;
        line-height: px-to-vw(22, 430);

        span {
          font-size: px-to-vw(14, 430);
          font-weight: 400;
        }
      }
    }
  }

  ul.options-container {
    grid-template-columns: auto auto;
  }

  .no_results {
    color: rgba($color: $white, $alpha: 0.5);
    text-align: center;
    border: none;
  }
}
